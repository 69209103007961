





































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import {
  CustomListCategory,
  DataListAssetBook,
  DataResponseAssetReclass,
  DataResponseCalendar,
} from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouseBranch,
  DataWarehouseLocation,
} from "@/models/interface/logistic.interface";
import { assetsServices } from "@/services/assets.service";
import moment from "moment";
import Vue from "vue";
import printJs from "print-js";
import { logisticServices } from "@/services/logistic.service";

interface DataAssetReclass {
  id: string;
  unitCode: string;
  serialNumber: string;
  assetNumber: number;
}

export default Vue.extend({
  data() {
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    this.getListCalendar = debounceProcess(this.getListCalendar, 200);
    this.getBranch = debounceProcess(this.getBranch, 200);
    return {
      params: {} as RequestQueryParamsModel,
      bookId: "" as string,
      loadingSearch: false as boolean,
      loadingListAssetCategory: false as boolean,
      loadingListAssetBook: false as boolean,
      loadingCalendar: false as boolean,
      loadingLocation: false as boolean,
      loadingDownload: false as boolean,
      loadingBranch: false as boolean,
      loading: false as boolean,
      loadingPrint: false as boolean,
      dataBranch: [] as DataWarehouseBranch[],
      dataAssetReclass: [] as DataAssetReclass[],
      dataListBook: [] as DataListAssetBook[],
      dataListCategory: [] as CustomListCategory[],
      dataListLocation: [] as DataWarehouseLocation[],
      dataCalendar: [] as DataResponseCalendar[],
      visible: false as boolean,
      selectAssetByBook: true as boolean,
      loadingListUnitCode: false as boolean,
      loadingListSerialNumber: false as boolean,
      totalElements: 0 as number,
      limit: 10 as number,
      page: 1 as number,
      form: this.$form.createForm(this, { name: "reportListOfAssets" }),
      formRules: {
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: [
            "unitCode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: [
            "serialNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        category: {
          label: "lbl_category",
          name: "category",
          placeholder: "lbl_category_placeholder",
          decorator: ["category"],
        },
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_book_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        periode: {
          label: "lbl_period",
          name: "periode",
          placeholder: "lbl_period_placeholder",
          decorator: ["periode"],
        },
        location: {
          label: "lbl_location",
          name: "location",
          placeholder: "lbl_location_plceholder",
          decorator: ["location"],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch_placeholder",
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      dataSource: [] as DataResponseAssetReclass[],
      columnsTable: [
        {
          title: this.$t("lbl_no"),
          dataIndex: "no",
          width: 70,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_asset_number"),
          dataIndex: "assetNo",
          key: "assetNo",
          width: 150,
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: 150,
        },
        {
          title: this.$t("lbl_old_category"),
          dataIndex: "oldCategory",
          key: "oldCategory",
          width: 200,
        },
        {
          title: this.$t("lbl_new_category"),
          dataIndex: "newCategory",
          key: "newCategory",
          width: 200,
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          key: "type",
          width: 150,
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 150,
        },
        {
          title: this.$t("lbl_spec"),
          dataIndex: "spec",
          key: "specification",
          width: 150,
        },
        {
          title: this.$t("lbl_cost"),
          dataIndex: "cost",
          key: "cost",
          width: 150,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          width: 150,
        },
        {
          title: this.$t("lbl_warehouse"),
          dataIndex: "warehouseName",
          key: "warehouse",
          width: 150,
        },
        {
          title: this.$t("lbl_transaction_date"),
          dataIndex: "transactionDate",
          key: "transactionDate",
          width: 200,
          scopedSlots: { customRender: "isCDate" },
        },
        {
          title: this.$t("lbl_period"),
          dataIndex: "period",
          key: "period",
          width: 200,
        },
      ],
    };
  },
  methods: {
    handleDownload() {
      if (this.bookId) {
        this.form.validateFields((err, res) => {
          if (err) return;
          const params: RequestQueryParamsModel = {};
          if (res.periode) {
            params.period = res.periode;
          }
          params.search = this.dynamicSearch(res);
          params.params = this.checkParams(res);
          this.loadingDownload = true;
          assetsServices
            .getDownloadReportReclass(params, this.bookId)
            .then((data) => {
              if (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "reports_Reclass.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
              }
            })
            .finally(() => (this.loadingDownload = false));
        });
      }
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(
        `,${
          this.dataBranch.find((dataFind) => dataFind.id === res["branch"])
            ?.name
        }`
      );
      params += this.checkValue(`,${res["assetNumber"]}`);
      params += this.checkValue(
        `,${
          this.dataListBook.find((dataFind) => dataFind.id === res["book"])
            ?.name
        }`
      );
      params += this.checkValue(`,${res["periode"]}`);
      return params;
    },
    handlePrint() {
      if (this.bookId) {
        this.form.validateFields((err, res) => {
          if (err) return;
          const params: RequestQueryParamsModel = {};
          params.search = this.dynamicSearch(res);
          params.params = this.checkParams(res);
          if (res.periode) {
            params.period = res.periode;
          }
          this.loadingPrint = true;
          assetsServices
            .getPrintReportReclass(params, this.bookId)
            .then((data) => {
              if (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                printJs(url);
              }
            })
            .finally(() => (this.loadingPrint = false));
        });
      }
    },
    getBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then((response) => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loadingBranch = false));
    },
    getListCalendar(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `month~*${valueSearch}*`;
      this.loadingCalendar = true;
      assetsServices
        .listCalendar(params)
        .then((data) => {
          let tempData = data.data.filter((dataFilter) => {
            return !dataFilter.month.includes("Adj");
          });
          this.dataCalendar = tempData;
        })
        .finally(() => (this.loadingCalendar = false));
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        search: `type~commercial_AND_active~true`,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then((data) => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    responsePageSizeChange(response: ResponsePagination) {
      this.limit = response.size;
      this.page = 1;
      this.submitForm();
    },
    responseCurrentPageChange(response: ResponsePagination) {
      this.page = response.page;
      this.submitForm();
    },
    handleCancel() {
      this.form.resetFields();
    },
    assignSearch(key, value, and): string {
      if (key === "unitCode" && value) return and + `asset.unitCode~${value}`;
      if (key === "serialNumber" && value)
        return and + `asset.serialNumber~${value}`;
      else if (key === "branch" && value)
        return (
          and +
          `asset.assetLocation.warehouse.branchWarehouse.secureId~${value}`
        );
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach((key) => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    submitForm() {
      this.form.validateFields((err, res) => {
        if (err) return;
        let params = {
          limit: 10,
          page: this.page - 1,
        } as RequestQueryParamsModel;
        if (res.periode) {
          params.period = res.periode;
        }
        params.search = this.dynamicSearch(res);
        this.loading = true;
        assetsServices
          .listReportReclass(params, res.book)
          .then((data) => {
            this.totalElements = data.totalElements;
            this.bookId = res.book;
            this.params = params;
            this.dataSource = data.data.map((dataMap, index) => {
              let tempOldCategory = dataMap.oldCategory.split(".");
              dataMap["no"] = data.currentPage * this.limit + (index + 1);
              tempOldCategory = tempOldCategory.map((dataMapOldCategory) => {
                let temp = dataMapOldCategory.split("");
                if (temp.length > 0) {
                  temp[0] = temp[0].toUpperCase();
                  dataMapOldCategory = temp.join("");
                }
                return dataMapOldCategory;
              });

              let tempNewCategory = dataMap.newCategory.split(".");

              tempNewCategory = tempNewCategory.map((dataMapNewCategory) => {
                let temp = dataMapNewCategory.split("");
                if (temp.length > 0) {
                  temp[0] = temp[0].toUpperCase();
                  dataMapNewCategory = temp.join("");
                }
                return dataMapNewCategory;
              });

              let tempDate = moment(dataMap.transactionDate).format(
                "DD MMM yyyy HH:MM:ss"
              );

              return {
                ...dataMap,
                oldCategory: tempOldCategory.join("."),
                newCategory: tempNewCategory.join("."),
                transactionDate: tempDate,
              };
            });
            this.visible = false;
          })
          .finally(() => (this.loading = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getListAssetReclass(valueSearch, type) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (this.bookId) {
        if (valueSearch && type === "unitCode") {
          this.form.setFieldsValue({ serialNumber: "" });
          params.search = `asset.unitCode~*${valueSearch}*`;
        } else if (valueSearch && type === "serialNumber") {
          this.form.setFieldsValue({ unitCode: "" });
          params.search = `asset.serialNumber~*${valueSearch}*`;
        }
        assetsServices.listReportReclass(params, this.bookId).then((data) => {
          this.dataAssetReclass = data.data.map<DataAssetReclass>((value) => {
            return {
              id: value.id,
              unitCode: value.unitCode,
              serialNumber: value.serialNumber,
              assetNumber: value.assetNo,
            };
          });
          this.dataAssetReclass = this.dataAssetReclass.filter(
            (dataFilter, index, self) =>
              index ===
              self.findIndex((t) => t.unitCode === dataFilter.unitCode)
          );
        });
      }
    },
    handleChangeBook(value) {
      this.bookId = value;
      this.selectAssetByBook = false;
      this.getListAssetReclass("", "unitCode");
    },
    handleChangeUnitCode(value) {
      this.getListAssetReclass(value, "unitCode");
    },
    handleChangeSerialNumber(value) {
      this.getListAssetReclass(value, "serialNumber");
    },
  },
  created() {
    this.getListAssetBook("");
    this.getListCalendar("");
    this.getBranch("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
